<template>
  <a-typography-title :level="5">
    Выберите шаги обработки
  </a-typography-title>

  <a-checkbox-group
    v-model:value="form.selectedSteps"
    :options="steps"
    @change="onStepsChange" />

  <a-divider />

  <a-form-item name="buildPriority">
    <a-select
      v-model:value="formState.buildPriority"
      :style="{ width: '100%' }"
      placeholder="Выберите приоритет">
      <a-select-option
        v-for="priority in BuildPriorityOptions"
        :key="priority.value"
        :value="Number(priority.value)">
        {{ priority.label }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <a-space
    v-if="form.selectedSteps.length > 0"
    direction="vertical"
    :style="{ width: '100%' }">
    <a-row
      class="settings-row"
      justify="space-between"
      :wrap="true">
      <a-card
        v-if="form.options.neural"
        :key="form.options.neural"
        title="Настройки нейронки"
        class="settings-card">
        <step-options
          :data="form.options.neural"
          :form-state="formState" />
      </a-card>
      <a-card
        v-if="form.options.furniture"
        :key="form.options.furniture"
        title="Настройки фурнитурки"
        class="settings-card">
        <step-options
          :data="form.options.furniture"
          :form-state="formState" />
      </a-card>
      <a-card
        v-if="form.options.unreal"
        :key="form.options.unreal"
        title="Настройки рендера"
        class="full-width-card"
        style="flex: 1">
        <step-options
          :data="form.options.unreal.filter(option => option.layout === 0)"
          :form-state="formState" />
      </a-card>
      <a-card
        v-if="form.options.unreal"
        :key="form.options.unreal"
        title="Настройки анрил панорам"
        class="settings-card">
        <step-options
          :data="form.options.unreal.filter(option => option.layout === 1)"
          :form-state="formState" />
      </a-card>
      <a-card
        v-if="form.options.unreal"
        :key="form.options.unreal"
        title="Настройки анрил видов"
        class="settings-card">
        <step-options
          :data="form.options.unreal.filter(option => option.layout === 2)"
          :form-state="formState" />
      </a-card>
    </a-row>
  </a-space>
</template>

<script setup>
import StepOptions from '@/Components/Service/Upload/StepOptions.vue';
import { Steps } from '@/composables/serviceFormOptions';
import { BuildPriorityOptions } from '@/enums/BuildPriority';

defineProps({
  form: {
    default: () => {},
    type: Object,
  },
  formState: {
    default: () => {},
    type: Object,
  },
  onStepsChange: {
    default: () => null,
    type: Function,
  },
});

const getSteps = () => {
  const neural = {
    value: Steps.Neural,
    label: 'Нейронка',
  };

  const furniture = {
    value: Steps.Furniture,
    label: 'Фурнитурка',
  };

  const unreal = {
    value: Steps.Unreal,
    label: 'Анрил',
  };

  return [neural, furniture, unreal];
};

const steps = getSteps();
</script>

<style>
.settings-row {
  width: calc(100% + 16px);
  margin-left: -8px;
}
@media (max-width: 900px) {
  .settings-row {
    margin-left: 0;
    width: 100%;
  }
}
.settings-card {
  flex: 1 1 calc(50% - 16px);
  margin: 8px;
}
@media (max-width: 900px) {
    .settings-card {
      flex: 1 1 100%;
      margin: 8px 0;
    }
}
.full-width-card {
  flex: 1 1 100%;
  min-width: 100%;
  margin: 8px 0;
}
</style>
