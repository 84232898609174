export const textureTypes = {
  BaseColor: 'Base Color',
  Metallic: 'Metallic',
  Specular: 'Specular',
  Roughness: 'Roughness',
  EmissiveColor: 'Emissive Color',
  Opacity: 'Opacity',
  Normal: 'Normal',
  AmbientOcclusion: 'Ambient Occlusion',
};
